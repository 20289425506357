header {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		background-color: $dark;
		height: 0%;
		z-index: -1;
		@include transition(all .5s ease-in-out);
	}

	.navbar {
		.navbar-brand {
			img {
				@include media-breakpoint-down(sm) {
					max-width: 100px;
				}
			}
		}
		.navbar-nav {
			.nav-item {
				.nav-link {
					--bs-nav-link-font-size: 1.125rem;
					text-transform: uppercase;
					@include link-underline-anim(var(--bs-nav-link-hover-color), 1px, var(--bs-navbar-nav-link-padding-x), var(--bs-nav-link-padding-y));

					@include media-breakpoint-down(xl) {
						--bs-navbar-nav-link-padding-x: 0rem;
						--bs-nav-link-color: #{$dark};
						--bs-nav-link-hover-color: #{$primary};
					}
				}
			}
		}
	}

	&.header-collapsed {
		&:before {
			height: 100%;
			box-shadow: $box-shadow;
		}
	}
}