@import "fonts";
@import "bootstrap";

@import "node_modules/@fancyapps/ui/dist/fancybox/fancybox";
@import "node_modules/swiper/swiper";
@import "node_modules/swiper/modules/navigation/navigation";
@import "node_modules/swiper/modules/pagination/pagination";
@import "node_modules/swiper/modules/free-mode/free-mode";
@import "node_modules/swiper/modules/grid/grid";

@import "mixins";
@import "colored-links";
@import "welcome";
@import "header";

.swiper-button-next, .swiper-button-prev {
    &.swiper-button-disabled {
        pointer-events: auto;
    }
}

.icon {
    --icon-width: 1em;
    --icon-height: 1em;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: var(--icon-width);
    height: var(--icon-height);
}

.icon-medium {
    --icon-width: 1.5em;
    --icon-height: 1.5em;
}

a {
    text-underline-offset: .25em;
}

.bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-secondary-custom {
    --bs-btn-border-width: 0;
    --bs-btn-border-radius: 50rem;
    --bs-btn-color: #{$white};
    --bs-btn-font-weight: 700;

    text-transform: uppercase;
    background: linear-gradient(180deg, tint-color($secondary, 50%) 0%, $secondary 100%);
    box-shadow: 0px 0px 11px 0px rgba($white, 0.25);
}

.parallax-items {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .parallax-item-1 {
        position: absolute;
        right: -10%;
        bottom: 20%;

        @include media-breakpoint-up(xxl) {
            right: 5%;
        }
    }

    .parallax-item-2 {
        position: absolute;
        bottom: 15%;
        left: -5%;

        @include media-breakpoint-up(xxl) {
            left: 5%;
        }
    }
}