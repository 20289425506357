.welcome {
  .welcome-slider {
    --swiper-pagination-bottom: 10%;
    --swiper-pagination-bullet-size: 12px;

    .slide-content {
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .slider-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      padding-top: 1rem;
      padding-bottom: 1rem;
      line-height: 1;
      color: $gray;
    }

    .swiper-pagination {
      position: relative;
    }

    @include media-breakpoint-up(md) {
      --swiper-navigation-sides-offset: 10%;
      --swiper-navigation-size: 64px;
    }
  }
}